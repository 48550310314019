import React, { useState } from 'react'
import cn from 'classnames'
import Carousel, { Modal, ModalGateway } from 'react-images'

import prevSvg from './img/prev.svg'
import nextSvg from './img/next.svg'
import css from './card.module.scss'

export default function Card({ item, withSeparator }) {
  const [stateOpen, setOpen] = useState(false)
  const [stateCurrentIndex, setCurrentIndex] = useState(0)
  const images = item.photos.map(photo => ({ src: photo.img, alt: '' }))

  const toggleLightbox = (index) => {
    setOpen(!stateOpen)
    setCurrentIndex(index)
  }

  return (
    <div className={cn(css.reviewCard, {[css.separator]: !!withSeparator})}>
      <span className={cn(css.name, 'h4-like')}>{ item.fio }</span>
      <span className={css.date}>{ item.created }</span>
      <p className={css.text}>
        { item.text }
      </p>
      {
        item.photos &&
          <div className={css.images}>
            {
              item.photos.filter((photo, index) => index < 4).map((photo, index) => {
                return (
                  <button
                    className={css.image}
                    style={{backgroundImage: `url(${photo.img})`}}
                    key={index}
                    onClick={() => toggleLightbox(index)}
                  >
                    {
                      index === 3 &&
                        <div className={cn(css.imageExtra, 'label')}>
                          Еще<br/>{ item.photos.length - 4 } фото
                        </div>
                    }
                  </button>
                )
              })
            }
          </div>
      }
      <ModalGateway>
        {stateOpen ? (
          <Modal onClose={toggleLightbox}>
            <Carousel
              styles={{
                footer: (base, state) => ({
                  ...base,
                  fontFamily: 'Roboto',
                }),
                navigationPrev: (base, state) => ({
                  ...base,
                  background: `url(${prevSvg}) center no-repeat`,
                  '& *': {
                    display: 'none',
                  },
                  '&:hover, &:active': {
                    background: `url(${prevSvg}) center no-repeat`,
                  },
                }),
                navigationNext: (base, state) => ({
                  ...base,
                  background: `url(${nextSvg}) center no-repeat`,
                  '& *': {
                    display: 'none',
                  },
                  '&:hover, &:active': {
                    background: `url(${nextSvg}) center no-repeat`,
                  },
                }),
              }}
              currentIndex={stateCurrentIndex}
              views={images}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}