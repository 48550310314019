import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Slider from 'react-slick'
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"
import cn from 'classnames'
import { Link } from 'gatsby'

import Card from './Card'
import css from './block.module.scss'
import { fetchReviews } from 'components/reviews/actions'

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={cn(css.arrows, css.arrowPrev)}
      onClick={onClick}
      role='button'
      aria-label='Previous slide'
      tabIndex='0'
      onKeyDown={() => {}}
    />
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={cn(css.arrows, css.arrowNext)}
      onClick={onClick}
      role='button'
      aria-label='Next slide'
      tabIndex='0'
      onKeyDown={() => {}}
    />
  );
}

class ReviewsSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reviews: []
    }
  }

  componentDidMount() {
    const { fetchReviews } = this.props

    fetchReviews('/review')
      .then(result => {
        this.setState({reviews: result.results})
      })
      .catch(e => {
        // console.error(e)
      })
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      dotsClass: cn(css.customDots, "slick-dots")
    }
    const { reviews } = this.state

    return (
      <div className={css.reviewSection}>
        <div className='container'>
          <div className={css.sliderWrap}>
            <h2>Отзывы</h2>

            <Slider className={css.reviewsSlider} {...settings}>
              {
                reviews.filter((item, index) => index < 3).map((item, index) => (
                  <Card
                    item={item}
                    key={index}
                  />
                ))
              }
            </Slider>
          </div>
          <div className={css.buttons}>
            <Link className='button-blue label --ghost' to='/make-review'>
              Оставить отзыв
            </Link>
            <br />
            <Link to='/reviews'>
              Посмотреть все отзывы
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    fetchReviews: bindActionCreators(fetchReviews, dispatch),
  })
)(ReviewsSlider)