import axios from 'axios'
import { navigate } from 'gatsby'

import { openPopup, closePopup, popupTypes } from 'components/popups/actions'
import { API_BASE_URL } from 'src/config'

export function openReviewSuccessPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_ALERT, {
      title: 'Спасибо за ваш отзыв!',
      text: 'Ваш отзыв успешно отправлен.',
      buttonText: 'На главную',
      onClickButton: () => {
        navigate('/')
        dispatch(closePopup())
      }
    }))
  }
}

export function postReview(orderId, formData) {
  return async (dispatch, getState) => {
    try {
      const { authToken } = getState().user
      const url = orderId
        ? `${API_BASE_URL}/order/${orderId}/review/`
        : `${API_BASE_URL}/review/`
      const headers = orderId
        ? {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        : {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      
      await axios({
        method: 'post',
        url: url,
        headers: headers,
        data: formData,
      })

      return Promise.resolve()
    } catch (e) {
      // console.error(e.response)

      return Promise.reject(e)
    }
  }
}

export function fetchReviews(q) {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'get',
        url: `${API_BASE_URL}${q}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return Promise.resolve(response.data)
    } catch (e) {
      // console.error(e.response)

      return Promise.reject(e)
    }
  }
}